import { CircularProgress } from "@mui/material";
import { SafeLeaseCard } from "@safelease/components";
import { usePerformanceBoardStore } from "./usePerformanceBoard";
import { TeamPerformanceBoardZeroState } from "./TeamPerformanceBoardZeroState";
import { TeamPerformanceBoardActiveState } from "./TeamPerformanceBoardActiveState";
import { Box } from "@mui/system";
import { useReputation } from "../../../useReputation";

export function TeamPerformanceBoard() {
  const { portfolioDashboardQuery, isPremium } = useReputation();
  const { facilities } = usePerformanceBoardStore();

  // List of all QR codes for all users across all facilities
  const globalQRCodes = portfolioDashboardQuery.data?.data?.facilities?.flatMap((facility) =>
    facility.users.flatMap((user) => user.qrCodes),
  );

  const setupComplete = (globalQRCodes ?? []).length > 0;
  const loading = portfolioDashboardQuery.isLoading;

  const users = facilities?.flatMap((facility) => facility.users);

  // Since we will have multiple facilities with the same users, we need to dedupe the users
  const dedupedUsers = users?.reduce((acc, user) => {
    if (!acc[user.id]) {
      acc[user.id] = user;
    }

    // append the reviews to the user
    if (!acc[user.id].reviews) {
      acc[user.id].reviews = [];
    }

    acc[user.id].reviews.concat(user.reviews ?? []);

    return acc;
  }, {});

  return (
    <SafeLeaseCard sx={{ p: 2, height: "100%" }}>
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", height: 300 }}>
          <CircularProgress size={40} />
        </Box>
      )}

      {!setupComplete && !loading && <TeamPerformanceBoardZeroState />}
      {isPremium && setupComplete && !loading && <TeamPerformanceBoardActiveState users={Object.values(dedupedUsers)} />}
    </SafeLeaseCard>
  );
}
