import { useState } from 'react';
import { Typography } from '@mui/material'
import { Stack } from '@mui/material'
import { SafeLeaseCard, SafeLeaseChip } from '@safelease/components'
import blueWaveBackground from '../assets/blueWaveBackground.png'
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendlyScheduleButton from './CalendlyScheduleButton';

type Props = {}

function UpgradeCTABanner({}: Props) {
  const [visible, setVisible] = useState(true);

  if (!visible) return null;

  return (
    <SafeLeaseCard
      sx={{
        p: 2,
        backgroundImage: `url(${blueWaveBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        position: 'relative'
      }}
    >
      <SafeLeaseChip
        label="Premium"
        sx={{
          color: 'black',
          backgroundColor: 'white',
          height: 34,
          borderRadius: 2,
          mb: 2,
          padding: '6px 16px',
          textAlign: 'center',
          cursor: 'default'
        }}
      />
      <IconButton
        onClick={() => setVisible(false)}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: 'white'
        }}
      >
        <CloseIcon />
      </IconButton>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack>
          <Typography variant="h6" color="white">
            Get 10x more reviews with Premium
          </Typography>
          <Typography variant="subtitle2" color="white" sx={{ width: '70%' }}>
            Waiting for feedback won't build the reputation you deserve. Take control by reaching out directly to tenants who know you best
            so they can share their positive experiences with your future tenants.
          </Typography>
        </Stack>
        <CalendlyScheduleButton
          url="https://calendly.com/zwood-asg/premium-reputation-management-intro-call"
          buttonText="Upgrade to Premium"
          variant="filled"
          rotateEndIcon={true}
          sx={{
            mx: 0,
            minWidth: 200,
            maxHeight: 40,
            whiteSpace: 'nowrap',
            color: 'black',
            borderColor: 'white',
            backgroundColor: 'white'
          }}
        />
      </Stack>
    </SafeLeaseCard>
  );
}

export default UpgradeCTABanner