import { Box, Divider, Stack, Theme, Typography } from "@mui/material";
import { SafeLeaseButton, SafeLeaseCard, SafeLeaseChip } from "@safelease/components";
import { PendingReviewsCounter } from "../../../PortfolioView/PendingReviewsCounter";
import { FacilityLogoAvatar } from "../../../../components/FacilityLogoAvatar";

interface FacilityOverviewHeaderProps {
  logo?: string;
  facilityName?: string;
  facilityAddress?: string;
  facilityTier?: string;
  activeCampaignCount?: number;
  reviewsPendingReplyCount?: number;
  handleReviewReplyDrawerOpen: () => void;
}

export function FacilityOverviewHeader({
  logo,
  facilityName,
  facilityAddress,
  facilityTier,
  reviewsPendingReplyCount,
  handleReviewReplyDrawerOpen,
}: FacilityOverviewHeaderProps) {
  return (
    <SafeLeaseCard sx={{ p: 2 }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start">
            <FacilityLogoAvatar src={logo} />
            <Stack direction="column" spacing={1}>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="h6" fontWeight="700">
                  {facilityName}
                </Typography>
                <SafeLeaseChip
                  label={facilityTier.charAt(0).toUpperCase() + facilityTier.slice(1) + " tier"}
                  sx={{
                    color: facilityTier === 'premium' ? 'rgba(25, 118, 210, 1)' : 'rgba(100, 100, 100, 1)',
                    backgroundColor: facilityTier === 'premium' ? 'rgba(50, 135, 255, 0.3)' : 'rgba(100, 100, 100, 0.3)',
                    height: 30,
                    borderRadius: 4,
                    padding: '6px 6px',
                    textAlign: 'center',
                    cursor: 'default'
                  }}
                />
              </Stack>
              <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }}>{facilityAddress}</Typography>
            </Stack>
          </Stack>
          <Divider flexItem orientation="vertical" />
          <SafeLeaseButton onClick={handleReviewReplyDrawerOpen}>
            <PendingReviewsCounter count={reviewsPendingReplyCount} />
          </SafeLeaseButton>
        </Stack>
      </Stack>
    </SafeLeaseCard>
  );
}
