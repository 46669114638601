import React, { useState } from 'react';
import { PopupModal } from 'react-calendly';
import { Button, SxProps, Theme } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { navyButton, ghostButtonNavy } from '../../styles/mui-overrides';

interface CalendlyScheduleButtonProps {
  url: string;
  buttonText: string;
  variant?: 'filled' | 'outlined';
  fullWidth?: boolean;
  hideEndIcon?: boolean;
  rotateEndIcon?: boolean;
  sx?: SxProps<Theme>;
}

const CalendlyScheduleButton: React.FC<CalendlyScheduleButtonProps> = ({
  url,
  buttonText,
  variant = 'filled',
  fullWidth = false,
  hideEndIcon = false,
  rotateEndIcon = false,
  sx = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        variant={variant === 'filled' ? 'contained' : 'outlined'}
        sx={{
          ...(variant === 'filled' ? navyButton : ghostButtonNavy),
          mx: 0,
          minWidth: 200,
          width: fullWidth ? '100%' : 'auto',
          fontWeight: 'bold',
          px: 2,
          justifyContent: 'space-between',
          ...(sx as any)
        }}
        endIcon={!hideEndIcon ? <ArrowForward sx={{ transform: rotateEndIcon ? 'rotate(-45deg)' : 'none' }} /> : undefined}
        onClick={() => setIsOpen(true)}
      >
        {buttonText}
      </Button>
      <PopupModal
        url={`${url}?hide_landing_page_details=1&hide_gdpr_banner=1&text_color=152744`}
        rootElement={document.getElementById('root')!}
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
      />
    </>
  );
};

export default CalendlyScheduleButton;
