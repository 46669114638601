import { Stack, Theme, Typography } from '@mui/material';
import { SafeLeaseButton } from '@safelease/components';
import _ from 'lodash';
import { TeamQRCodeSetupDialog } from './TeamPerformanceBoardSetup';
import { usePerformanceBoard} from './usePerformanceBoard';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CalendlyScheduleButton from '../../../components/CalendlyScheduleButton';
import { navyButton } from '../../../../styles/mui-overrides';
import { useReputation } from '../../../useReputation';

export function TeamPerformanceBoardZeroState() {
  const { configDialogOpen, setConfigDialogOpen } = usePerformanceBoard();
  const { isPremium } = useReputation();

  return (
    isPremium ? (
          <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100%', px: 6 }}>
            <Typography sx={{ fontWeight: 600 }} variant="h6">
              Team Performance Board
            </Typography>
            <Typography sx={{ fontWeight: 300, mb: 2, color: "gray" }} variant="subtitle2">
              Track reviews solicited by your employees using their unique QR codes and links
            </Typography>
            <Typography sx={{ fontWeight: 600 }} variant="h6">
              Track reviews solicited by your employees
            </Typography>
            <SafeLeaseButton color="navy" variant="contained" onClick={() => setConfigDialogOpen(true)}>
              Get started
            </SafeLeaseButton>
            <Typography sx={{ textAlign: "center", color: (theme: Theme) => theme.palette.grey.A100 }} variant="body1">
              Get started by activating QR codes for all your employees. Each employee will receive a personalized review solicitation QR code
              and link via email or text message for each facility they work at.
            </Typography>
            <TeamQRCodeSetupDialog open={configDialogOpen} handleClose={() => setConfigDialogOpen(false)} />
          </Stack>
        ) : (
          <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{ height: '100%', px: 6 }}>
            <LockOutlinedIcon sx={{ fontSize: 50 }} />
            <Typography sx={{ fontWeight: 600 }} variant="h6">
              Upgrade a facility to view this feature
            </Typography>
            <Typography sx={{ fontWeight: 300, mb: 2, color: 'gray' }} variant="subtitle2">
              Upgrade one or more of your facilities to the premium plan to unlock this feature
            </Typography>
            <CalendlyScheduleButton
              url="https://calendly.com/zwood-asg/premium-reputation-management-intro-call"
              buttonText="Upgrade to Premium"
              rotateEndIcon={true}
        sx={{ ...navyButton, mx: 0, minWidth: 200 }}
      />
    </Stack>
  ));
}
