import { Formatters } from "./formatters";
import { claimStatusPillRenderer } from "../column-renderers/claim-status-pill";
import { relationshipActionsRenderer } from "../column-renderers/relationship-actions-renderer";
import { llcActionsRenderer } from "../column-renderers/llc-actions-renderer";
import { locationActionsRenderer } from "../column-renderers/location-actions-renderer";
import { jobStatusRenderer } from "../column-renderers/job-status";
import {
  linkRenderer,
  confirmClaimCheckbox,
  confirmInvoiceCheckbox,
  booleanRenderer,
  editableFieldWithDropdown,
  editableField,
  editableMonthField,
  BooleanSwitch,
} from "../column-renderers/common-renderers";
import ClaimsDataService from "../services/claims.service";
import SetupDataService from "../services/setup.service";
import BillingDataService from "../services/billing.service";
import AttachmentDataService from "../services/attachments.service";
import { Llc } from "../utilities/generated/gql-types";
import { QueryResult } from "@apollo/client";
import { TableRow } from "../utilities/table-state";
import { safeleaseStatuses } from "../utilities/safelease-statuses";
import { billingStatuses } from "../utilities/billing-statuses";
import { invoiceStatuses } from "../utilities/invoice-statuses";
import { Link, useHistory } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import DownloadIcon from "@mui/icons-material/Download";
import {
  days,
  getFileTypeInfo,
  SelectOptions,
  FileTypes,
} from "./field-enums";
import { mixpanelEventHandler } from "./reactMixpanelHandler";
import FileSaver from "file-saver";
import {
  IconButton,
} from "@mui/material";
import { Image } from "../image";

export type RenderOptions = {
  callback?: (newValue: any, tableInfo: any) => Promise<any>;
  selectOptions?: (row?: TableRow) => Readonly<SelectOptions<string>[]>;
  digits?: number;
  nonAdminsCannotEdit?: boolean;
  nonEditingDisplayParser?: any;
  editingDisplayParser?: (value: string, tableInfo) => string;
  validators?: {
    validate: (value: string) => boolean;
    errorMessage: string;
  }[];
};

export interface ColumnDef {
  key: string;
  title: string;
  renderer?: (
    data: any,
    row?: any,
    options?: any
  ) => JSX.Element | string | number | Date | undefined;
  renderOptions?: RenderOptions;
  tooltip?: string;
  clearance?: string; // can be 'admin', 'manager' etc...
  styles?: { [key: string]: any }; // note that styles are currently only rendered on the ReportTableRows
}

export const isColumnHidden = (
  column: ColumnDef,
  userIsAdmin: boolean,
  activeColumnKeys: string[]
) => {
  if (column.clearance === "admin" && !userIsAdmin) return true;
  return !activeColumnKeys ? false : !activeColumnKeys.includes(column.key);
};

const reportDetailsColumnDefs: ColumnDef[] = [
  {
    key: "address",
    title: "Location",
  },
  {
    key: "name",
    title: "Unit Number",
  },
  {
    key: "tenantName",
    title: "Tenant Name",
  },
  {
    key: "startDate",
    title: "Enrollment Date",
  },
  {
    key: "moveOutDate",
    title: "Move Out Date",
  },
  {
    key: "cancelDate",
    title: "Cancel Date",
  },
  {
    key: "coverage",
    title: "Coverage",
    renderer: Formatters.penniesToDollarsTableRenderer,
    renderOptions: { digits: 0 },
  },
  {
    key: "premium",
    title: "Fee",
    renderer: Formatters.penniesToDollars,
  },
  {
    key: "collectedSum",
    title: "Collected",
    renderer: Formatters.penniesToDollars,
  },
  {
    key: "paidThroughDate",
    title: "Paid Through Date",
  },
];

const claimsColumnDefs: ColumnDef[] = [
  {
    key: "shortStatus",
    title: "Status",
    renderer: claimStatusPillRenderer,
  },
  {
    key: "matchedLocation",
    title: "Location",
  },
  {
    key: "name",
    title: "Tenant",
  },
  {
    key: "causes",
    title: "Cause",
    renderer: (causes?: string[]) => <>{causes?.join(", ")}</>,
  },
  {
    key: "unit",
    title: "Unit",
  },
  {
    key: "lossDate",
    title: "Loss Date",
    renderer: Formatters.localize,
  },
  {
    key: "claimSubmissionDate",
    title: "Claim Submission",
    renderer: Formatters.localize,
  },
  {
    key: "claimAmount",
    title: "Claim Amount",
    renderer: Formatters.formatDollarSign,
  },
  {
    key: "coverageAmount",
    title: "Coverage Amount",
    renderer: Formatters.formatDollarSign,
  },
];

const matchClaimsColumnDefs: ColumnDef[] = [
  {
    key: "locationConfirmed",
    title: "Confirmed",
    renderer: booleanRenderer,
  },
  {
    key: "externalLocation",
    title: "External Location",
    renderer: linkRenderer,
  },
  {
    key: "matchedLocation",
    title: "Matched Location",
  },
  {
    key: "matchAccuracy",
    title: "Match Accuracy",
    renderer: Formatters.formatFractionAsPercentage,
  },
];

const reportsColumnDefs: ColumnDef[] = [
  {
    key: "address",
    title: "Location",
    renderer: linkRenderer,
  },
  {
    key: "occupiedUnits",
    title: "Occupied Units",
    renderer: Formatters.formatLocaleString,
    tooltip: "The current number of rented units at your facility.",
  },
  {
    key: "insured",
    title: "Covered Units",
    renderer: Formatters.formatLocaleString,
    tooltip: "The number of occupied units enrolled in the protection program.",
  },
  {
    key: "attachRate",
    title: "Enrollment Rate",
    tooltip:
      "The percentage of occupied units enrolled in the protection program.",
  },
  {
    key: "rolloverDate",
    title: "Rollover",
    renderer: Formatters.jsDateToString,
  },
  {
    key: "autoEnrollExistingEffectiveDate",
    title: "Auto-Protect",
    renderer: Formatters.jsDateToString,
  },
  {
    key: "liveDate",
    title: "Live Date",
    renderer: Formatters.jsDateToString,
  },
];

const possibleClaimMatchesColumnDefs: ColumnDef[] = [
  {
    key: "locationConfirmed",
    title: "Confirmed",
    renderer: confirmClaimCheckbox,
    renderOptions: {
      callback: ClaimsDataService.confirm,
    },
  },
  {
    key: "matchedLocation",
    title: "Possible Match",
  },
  {
    key: "matchAccuracy",
    title: "Match Accuracy",
    renderer: Formatters.formatFractionAsPercentage,
  },
  {
    key: "locationId",
    title: "Location ID",
  },
  {
    key: "id",
    title: "Claim ID",
  },
];

const jobHealthColumnDefs: ColumnDef[] = [
  {
    key: "status",
    title: "Status",
    renderer: jobStatusRenderer,
  },
  {
    key: "id",
    title: "Job ID",
    styles: {
      width: 140,
    },
  },
  {
    key: "locationId",
    title: "Location ID",
    styles: {
      width: 100,
    },
  },
  {
    key: "address",
    title: "Address",
    styles: {
      width: 160,
    },
  },
  {
    key: "method",
    title: "Method",
    styles: {
      width: 220,
    },
  },
  {
    key: "runAt",
    title: "Run At",
    styles: {
      width: 140,
    },
  },
  {
    key: "lastCompletedAt",
    title: "Last Completed At",
    styles: {
      width: 140,
    },
  },
  {
    key: "failedAt",
    title: "Failed At",
    styles: {
      width: 220,
    },
  },
  {
    key: "failureCount",
    title: "Failure Count",
    styles: {
      width: 100,
    },
  },
  {
    key: "error",
    title: "Error",
    styles: {
      width: 200,
    },
  },
];

const usersColumnDefs: ColumnDef[] = [
  {
    key: "name",
    title: "Name",
  },
  {
    key: "email",
    title: "Email",
  },
  {
    key: "role",
    title: "Role",
  },
  {
    key: "relationshipName",
    title: "Relationship",
  },
  {
    key: "llcsSignedFor",
    title: "ACH Signatures",
  },
];

const relationshipsColumnDefs: ColumnDef[] = [
  {
    key: "name",
    title: "Relationship Name",
  },
  {
    key: "createdAt",
    title: "Created At",
    renderer: Formatters.localize,
  },
  {
    key: "earliestLiveDate",
    title: "Earliest Live Date",
  },
  {
    key: "totalLocations",
    title: "Locations",
  },
  {
    key: "allFms",
    title: "FMS",
  },
  {
    key: "totalActiveJobs",
    title: "Active Jobs",
  },
  {
    key: "totalFailedJobs",
    title: "Failed Jobs",
  },
  {
    key: "safeleaseStatus",
    title: "Status",
    renderer: (value, rowInfo) =>
      safeleaseStatuses[rowInfo.row.safeleaseStatus].label || "Unknown",
  },
  {
    key: "id",
    title: "Actions",
    renderer: relationshipActionsRenderer,
  },
];

const llcsColumnDefs: ColumnDef[] = [
  {
    key: "name",
    title: "LLC Name",
    renderer: editableField,
    renderOptions: {
      callback: async (
        newValue: string,
        tableInfo: { row: TableRow; queryResult: QueryResult }
      ) => {
        await BillingDataService.updateLlcByField(
          { name: newValue },
          tableInfo.row as Llc
        );
        await tableInfo.queryResult.refetch();
      },
    },
  },
  {
    key: "fullAddress",
    title: "Address",
  },
  {
    key: "signature",
    title: "Signature",
  },
  {
    key: "email",
    title: "Email",
  },
  {
    key: "billingStatus",
    title: "Billing Status",
    renderer: (value, rowInfo) =>
      billingStatuses[rowInfo.row.billingStatus].label || "Unknown",
  },
  {
    key: "invoiceDay",
    title: "Invoice Day",
    renderer: editableFieldWithDropdown,
    renderOptions: {
      callback: async (
        newValue: string,
        tableInfo: { row: TableRow; queryResult: QueryResult }
      ) => {
        await BillingDataService.updateLlcByField(
          { invoiceDay: Number(newValue) },
          tableInfo.row as Llc
        );
        await tableInfo.queryResult.refetch();
      },
      selectOptions: () => days(),
    },
  },
  {
    key: "dueDay",
    title: "Due Day",
    renderer: editableFieldWithDropdown,
    renderOptions: {
      callback: async (
        newValue: string,
        tableInfo: { row: TableRow; queryResult: QueryResult }
      ) => {
        await BillingDataService.updateLlcByField(
          { dueDay: Number(newValue) },
          tableInfo.row as Llc
        );
        await tableInfo.queryResult.refetch();
      },
      selectOptions: () => days(),
    },
  },
  {
    key: "safeleaseStatus",
    title: "Status",
    renderer: (value, rowInfo) =>
      safeleaseStatuses[rowInfo.row.safeleaseStatus].label || "Unknown",
  },
  {
    key: "id",
    title: "Actions",
    renderer: llcActionsRenderer,
  },
];

const subLocationsColumnDefs: ColumnDef[] = [
  {
    key: "address",
    title: "Location Address",
  },
  //{
  //  key: 'strategy',
  //  title: 'Strategy'
  //},
  //{
  //  key: 'accountType',
  //  title: 'FMS'
  //},
  //{
  //  key: 'rolloverDate',
  //  title: 'Rollover Date'
  //},
  //{
  //  key: 'autoEnrollStartDate',
  //  title: 'Auto-Protect Start Date'
  //},
  {
    key: "fms",
    title: "FMS",
  },
  {
    key: "safeleaseUnits",
    title: "SafeLease Units",
  },
  {
    key: "attachRate",
    title: "Attach Rate",
  },
  {
    key: "rolloverDate",
    title: "Rollover Date",
  },
  {
    key: "autoEnrollExistingEffectiveDate",
    title: "Auto Enroll Existing Effective Date",
  },
  {
    key: "safeleaseRevenueFormatted",
    title: "SafeLease Revenue",
  },
  {
    key: "activeJobs",
    title: "Active Jobs",
  },
  {
    key: "failedJobs",
    title: "Failed Jobs",
  },
  {
    key: "safeleaseStatus",
    title: "Status",
    renderer: (value, rowInfo) =>
      safeleaseStatuses[rowInfo.row.safeleaseStatus].label || "Unknown",
  },
  {
    key: "id",
    title: "Actions",
    renderer: locationActionsRenderer,
  },
];

const emailColumnDefs: ColumnDef[] = [
  {
    key: "userName",
    title: "User Name",
  },
  {
    key: "relationshipName",
    title: "Relationship Name",
  },
  {
    key: "nextSendAt",
    title: "Next Send",
  },
  {
    key: "lastSendAt",
    title: "Last Send",
  },
  {
    key: "emailName",
    title: "Email Name",
  },
  {
    key: "location",
    title: "Location",
  },
  {
    key: "rolloverDate",
    title: "Rollover Date",
  },
  {
    key: "autoEnrollDate",
    title: "Auto Protect Date",
  },
  {
    key: "disabled",
    title: "Disabled",
    renderer: BooleanSwitch,
    renderOptions: {
      callback: async (
        newValue: boolean,
        tableInfo: { row: TableRow; queryResult: QueryResult }
      ) => {
        await SetupDataService.editSubscription({
          disabled: newValue,
          subscriptionId: tableInfo.row.id,
        });
        await tableInfo.queryResult.refetch();
      },
    },
  },
  {
    key: "relationshipSendDisabled",
    title: "Relationship Send Disabled",
    renderer: BooleanSwitch,
    renderOptions: {
      callback: async (
        newValue: boolean,
        tableInfo: { row: TableRow; queryResult: QueryResult }
      ) => {
        await SetupDataService.editSubscription({
          emailSendDisabled: newValue,
          relationshipId: tableInfo.row.relationshipId,
        });
        await tableInfo.queryResult.refetch();
      },
    },
  },
];

const internalNotesColumnDefs: ColumnDef[] = [
  {
    key: "userName",
    title: "Author",
  },
  {
    key: "objectName",
    title: "Object",
  },
  {
    key: "createdAt",
    title: "Date and Time",
    renderer: Formatters.jsDateToLocalTime,
  },
  {
    key: "content",
    title: "Content",
  },
];

const jsonRenderer = (value) =>
  typeof value === "object" || typeof value === "boolean"
    ? JSON.stringify(value)
    : value;

const changesColumnDefs: ColumnDef[] = [
  {
    key: "source",
    title: "Source",
  },
  {
    key: "objectName",
    title: "Object",
  },
  {
    key: "key",
    title: "Attribute",
  },
  {
    key: "previousValue",
    title: "Previous value",
    renderer: jsonRenderer,
  },
  {
    key: "value",
    title: "New value",
    renderer: jsonRenderer,
  },
  {
    key: "createdAt",
    title: "Date",
    renderer: Formatters.jsDateToLocalTime,
  },
  {
    key: "content",
    title: "Note",
  },
];

const relationshipsByClaimsColumnDefs: ColumnDef[] = [
  {
    key: "name",
    title: "Name",
  },
  {
    key: "countPaid",
    title: "Settled",
  },
  {
    key: "totalPaid",
    title: "Paid",
    renderer: Formatters.formatDollarSign,
  },
  {
    key: "countRisk",
    title: "Open",
  },
  {
    key: "totalRisk",
    title: "Limit",
    renderer: Formatters.formatDollarSign,
  },
];

const llcsByClaimsColumnDefs: ColumnDef[] = [
  {
    key: "name",
    title: "Name",
  },
  {
    key: "countPaid",
    title: "Settled",
  },
  {
    key: "totalPaid",
    title: "Paid",
    renderer: Formatters.formatDollarSign,
  },
  {
    key: "countRisk",
    title: "Open",
  },
  {
    key: "totalRisk",
    title: "Limit",
    renderer: Formatters.formatDollarSign,
  },
];

const insurancePenetrationColumns: ColumnDef[] = [
  {
    key: "address",
    title: "Address",
  },
  {
    key: "city",
    title: "City",
  },
  {
    key: "state",
    title: "State",
  },
  {
    key: "newPolicies",
    title: "New Units Protected",
  },
  {
    key: "newLeasesCount",
    title: "New Units",
  },
  {
    key: "newInsuredPercent",
    title: "New Units Protected %",
    renderer: Formatters.formatPercentage,
  },
  {
    key: "reportSafeleaseUnits",
    title: "Protected Units",
  },
  {
    key: "reportOccupiedUnits",
    title: "Occupied Units",
  },
  {
    key: "insuredPercent",
    title: "Units Protected %",
    renderer: Formatters.formatPercentage,
  },
  {
    key: "reportTotalCoverage",
    title: "Total Coverage",
    renderer: Formatters.penniesToDollarsTableRenderer,
    renderOptions: { digits: 0 },
  },
];

const dailySummaryColumns: ColumnDef[] = [
  {
    key: "Location.address",
    title: "Address",
    styles: {
      backgroundColor: "white",
      position: "sticky",
      minWidth: "10rem",
      left: 0,
    },
  },
  {
    key: "Location.city",
    title: "City",
    styles: {
      backgroundColor: "white",
      position: "sticky",
      minWidth: "5rem",
      left: "10rem",
    },
  },
  {
    key: "Location.state",
    title: "State",
    styles: {
      backgroundColor: "white",
      position: "sticky",
      minWidth: "3rem",
      left: "15rem",
    },
  },
  {
    key: "projectedRent",
    title: "Projected Rent",
    renderer: Formatters.penniesToDollarsTableRenderer,
    renderOptions: { digits: 2 },
  },
  {
    key: "receivableRent",
    title: "Receivables",
    renderer: Formatters.penniesToDollarsTableRenderer,
    renderOptions: { digits: 2 },
  },
  {
    key: "receivableRentMoreThanThirtyDays",
    title: "Receivables 30+ Days",
    renderer: Formatters.penniesToDollarsTableRenderer,
    renderOptions: { digits: 2 },
    styles: { minWidth: "6rem" },
  },
  {
    key: "receivableRentMoreThanThirtyDaysPercent",
    title: "Receivables 30+ Days as % of Rent",
    renderer: Formatters.formatPercentage,
    styles: { minWidth: "10rem" },
  },
  {
    key: "totalUnits",
    title: "Total Units",
    renderer: Formatters.formatLocaleString,
  },
  {
    key: "totalSquareFootage",
    title: "Total Square Footage",
    renderer: Formatters.formatLocaleString,
    styles: { minWidth: "8rem" },
  },
  {
    key: "occupiedSquareFootage",
    title: "Occupied Square Footage",
    renderer: Formatters.formatLocaleString,
    styles: { minWidth: "8rem" },
  },
  {
    key: "occupiedSquareFootagePrevMonth",
    title: "Last Month Occupied Square Footage",
    renderer: Formatters.formatLocaleString,
    styles: { minWidth: "10rem" },
  },
  {
    key: "deltaSquareFootage",
    title: "Occupied Square Footage Difference",
    renderer: Formatters.formatLocaleString,
    styles: { minWidth: "10rem" },
  },
  {
    key: "occupiedSquareFootagePercent",
    title: "Occupied % (sf)",
    renderer: Formatters.formatPercentage,
    styles: { minWidth: "5rem" },
  },
  {
    key: "beginningOccupancy",
    title: "Beginning Occupancy",
    renderer: Formatters.formatLocaleString,
  },
  {
    key: "endingOccupancy",
    title: "Ending Occupancy",
    renderer: Formatters.formatLocaleString,
  },
  {
    key: "moveIns",
    title: "Move Ins",
    renderer: Formatters.formatLocaleString,
  },
  {
    key: "moveOuts",
    title: "Move Outs",
    renderer: Formatters.formatLocaleString,
  },
  {
    key: "netMoveIns",
    title: "Net Move Ins",
    renderer: Formatters.formatLocaleString,
    styles: { minWidth: "5rem" },
  },
  {
    key: "occupiedUnitsPercent",
    title: "Occupied Units %",
    renderer: Formatters.formatPercentage,
    styles: { minWidth: "6rem" },
  },
  {
    key: "unoccupiedUnits",
    title: "Vacant Units",
  },
];

const occupancySummaryColumns: ColumnDef[] = [
  {
    key: "address",
    title: "Address",
    styles: {
      backgroundColor: "white",
      position: "sticky",
      minWidth: "10rem",
      left: 0,
    },
  },
  {
    key: "city",
    title: "City",
    styles: {
      backgroundColor: "white",
      position: "sticky",
      minWidth: "5rem",
      left: "10rem",
    },
  },
  {
    key: "state",
    title: "State",
    styles: {
      backgroundColor: "white",
      position: "sticky",
      minWidth: "3rem",
      left: "15rem",
    },
  },
  {
    key: "type",
    title: "Type",
    styles: { minWidth: "12rem" },
  },
  {
    key: "size",
    title: "Size",
  },
  {
    key: "rate",
    title: "Rate",
    renderer: Formatters.penniesToDollarsTableRenderer,
    renderOptions: { digits: 2 },
  },
  {
    key: "totalArea",
    title: "Total SF",
    renderer: Formatters.formatLocaleString,
  },
  {
    key: "count",
    title: "Total Units",
    renderer: Formatters.formatLocaleString,
  },
  {
    key: "occupied",
    title: "Occupied",
  },
  {
    key: "vacant",
    title: "Vacant",
  },
  {
    key: "reserved",
    title: "Reserved",
  },
  {
    key: "other",
    title: "Other",
  },
  {
    key: "projectedRent",
    title: "Projected Rent",
    renderer: Formatters.penniesToDollarsTableRenderer,
    renderOptions: { digits: 2 },
  },
  // { // TODO: We may or may not keep these columns in this report
  //   key: '',
  //   title: 'Net Effective Rate',
  // },
  // {
  //   key: '',
  //   title: 'NEPI',
  // },
  // {
  //   key: '',
  //   title: 'Net Eff Rate Per SF',
  // },
  {
    key: "totalRate",
    title: "Gross Potential",
    renderer: Formatters.penniesToDollarsTableRenderer,
    renderOptions: { digits: 2 },
  },
  {
    key: "totalRatePerSF",
    title: "Gross Potential per SF",
    renderer: Formatters.penniesToDollarsTableRenderer,
    renderOptions: { digits: 2 },
  },
  {
    key: "economicOccupancy",
    title: "Economic Occupancy %",
    renderer: Formatters.formatPercentage,
  },
];

// Keep around for CSV exporting
const legacyJobsErrorReportColumnDefs: ColumnDef[] = [
  {
    key: "id",
    title: "Actions",
  },
  {
    key: "team",
    title: "Team",
  },
  {
    key: "csm",
    title: "CSM",
  },
  {
    key: "relationshipName",
    title: "Relationship Name",
  },
  {
    key: "locationAddress",
    title: "Location",
  },
  {
    key: "failedAt",
    title: "Failed At",
  },
  {
    key: "method",
    title: "Method",
  },
  {
    key: "triagingStatus",
    title: "Triaging Status",
  },
  {
    key: "error",
    title: "Error",
  },
  {
    key: "errorNotes",
    title: "Error Notes",
  },
  {
    key: "csmNotes",
    title: "CSM Notes",
  },
  {
    key: "csmResolutionSteps",
    title: "CSM Resolution Steps",
  },
];


export {
  matchClaimsColumnDefs,
  jobHealthColumnDefs,
  possibleClaimMatchesColumnDefs,
  reportDetailsColumnDefs,
  claimsColumnDefs,
  reportsColumnDefs,
  usersColumnDefs,
  relationshipsColumnDefs,
  llcsColumnDefs,
  subLocationsColumnDefs,
  emailColumnDefs,
  internalNotesColumnDefs,
  changesColumnDefs,
  llcsByClaimsColumnDefs,
  relationshipsByClaimsColumnDefs,
  insurancePenetrationColumns,
  dailySummaryColumns,
  occupancySummaryColumns,
  legacyJobsErrorReportColumnDefs
};
