import { Box, ButtonBase, Divider, Rating, Stack, Theme, Tooltip, Typography, useTheme } from "@mui/material";
import { SafeLeaseCard, ChangeText, SafeLeaseChip } from "@safelease/components";
import { CampaignCounter } from "./CampaignCounter";
import { PendingReviewsCounter } from "./PendingReviewsCounter";
import { useHistory } from "react-router-dom";
import { useReputation } from "../../useReputation";
import { useAuth } from "../../../auth";
import { SetupValidationDisplay } from "../../components/SetupValidationDisplay";
import { Facility_Reputation } from "@safelease/service-utilities";

interface FacilityListItemProps {
  id: string;
  name: string;
  reviewCount: number;
  currentRating: number;
  weeklyRatingChange: number;
  activeCampaignCount: number;
  reviewsPendingReply: number;
}

export function FacilityListItem({
  id,
  name,
  reviewCount,
  currentRating,
  weeklyRatingChange = 0,
  activeCampaignCount,
  reviewsPendingReply,
}: FacilityListItemProps) {
  const theme = useTheme();

  // Prep for upgrading react router
  const { setSelectedFacilityId, portfolio, reputationRoute } = useReputation();
  const { push: navigate } = useHistory();
  const auth = useAuth();
  const user = auth.user;

  const facility: Facility_Reputation = portfolio?.facilities.find((facility) => facility.id === id);
  if (!facility) return <></>;
  const facilityHasPassedOffboardedDate = facility.offboardedDate && new Date(facility.offboardedDate) < new Date();

  return (
    <ButtonBase
      sx={{ width: "100%", p: 0 }}
      onClick={() => {
        if (facilityHasPassedOffboardedDate && !user.isAdmin) {
          alert(
            "This facility has been offboarded. To re-start SafeLease Reputation for this facility, please contact your Customer Success Manager."
          );
          return;
        }
        setSelectedFacilityId(id);
        navigate(`${reputationRoute}/facility/${id}`);
      }}
    >
      <SafeLeaseCard
        sx={{
          p: 2,
          width: "100%",
          cursor: "pointer",
          "&:hover": {
            border: "2px solid",
            borderColor: theme.palette.primary.light,
            boxShadow: "0 0 20px rgba(0,0,0,0.10)",
          },
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Box sx={{ width: 200, textAlign: 'left' }}>
              <Typography sx={{ fontWeight: 700 }}>{name}</Typography>
              <SafeLeaseChip
                label={facility.tier.charAt(0).toUpperCase() + facility.tier.slice(1) + " tier"}
                sx={{
                  color: facility.tier === 'premium' ? 'rgba(25, 118, 210, 1)' : 'rgba(100, 100, 100, 1)',
                  backgroundColor: facility.tier === 'premium' ? 'rgba(50, 135, 255, 0.3)' : 'rgba(100, 100, 100, 0.3)',
                  height: 30,
                  borderRadius: 4,
                  padding: '6px 6px',
                  textAlign: 'center',
                  cursor: 'default'
                }}
              />
              <Typography sx={{ color: (theme: Theme) => theme.palette.grey.A100 }} variant="subtitle2">
                {reviewCount} reviews
              </Typography>
            </Box>
            {/* If partner has been offboarded, show offboarded message */}
            {facilityHasPassedOffboardedDate && (
              <>
                <Divider orientation="vertical" sx={{ borderColor: "#EBEFF7" }} flexItem />
                <Tooltip
                  title={
                    user.isAdmin
                      ? "Partners are unable to click into this facility as it has been offboarded. Only Admins can click in."
                      : ""
                  }
                >
                  <Typography sx={{ color: (theme: Theme) => theme.palette.error.main }} variant="body1">
                    Offboarded on {new Date(facility.offboardedDate).toLocaleDateString()}
                  </Typography>
                </Tooltip>
              </>
            )}
            {/* If partner is still live, show ratings bar */}
            {!facilityHasPassedOffboardedDate && (
              <>
                <Divider orientation="vertical" sx={{ borderColor: "#EBEFF7" }} flexItem />
                <Stack direction="row" alignItems="center" spacing={2} sx={{ width: 160 }}>
                  <Typography sx={{ fontWeight: 700 }}>{Number(currentRating).toFixed(1)}</Typography>
                  <Rating value={Number(currentRating)} readOnly sx={{ mr: 5 }} />
                </Stack>
              </>
            )}
            <Divider orientation="vertical" sx={{ borderColor: "#EBEFF7" }} flexItem />
            <ChangeText
              value={Number(currentRating) ?? 0}
              previousValue={(Number(currentRating) ?? 0) - (Number(weeklyRatingChange) ?? 0)}
              periodSize={7}
              periodType="days"
            />
            {activeCampaignCount > 0 && (
              <>
                <Divider orientation="vertical" sx={{ borderColor: "#EBEFF7" }} flexItem />
                <CampaignCounter count={activeCampaignCount} />
              </>
            )}
          </Stack>
          <SetupValidationDisplay facility={facility} displayType="narrowCard" />
          <PendingReviewsCounter count={reviewsPendingReply} />
        </Stack>
      </SafeLeaseCard>
    </ButtonBase>
  );
}
