// UI - Libs
import { Alert, Box, CircularProgress, Divider, Grid, Stack, Theme, Typography } from "@mui/material";
import { ReplyOutlined } from "@mui/icons-material";

// UI - internal
import { SafeLeaseButton, SafeLeaseCard } from "@safelease/components";
import { PortfolioViewHeader } from "./PortfolioView/PortfolioViewHeader";
import { FacilityList } from "./PortfolioView/FacilityList";
import { useReputation } from "../useReputation";
import { RatingsDisplay } from "../components/RatingsDisplay";
import { RatingBreakdownGraphs } from "../components/RatingBreakdownGraphs";
import { ReviewReplyDrawer } from "./ReviewReplyDrawer";


// Hooks
import { useEffect } from "react";

// Data
import { mixpanelEventHandler } from "../../utilities/reactMixpanelHandler";

// Utils
import dayjs from "dayjs";
import TitleHeader from "../../shared/title-header";
import RouterHelper from "../../utilities/router-helper";
import { PrivateReview_Reputation as PrivateReview } from "@safelease/service-utilities";
import { TeamPerformanceBoard } from "./PortfolioView/TeamPerformanceBoard";
import PortfolioAdminTools from "./PortfolioView/PortfolioAdminTools/PortfolioAdminTools";
import { AdminsOnly } from "../components/AdminsOnly";
import FacilityNotInRepList from "./PortfolioView/FacilityNotInRepList";
import UpgradeCTABanner from "../components/UpgradeCTABanner";

interface PortfolioViewProps {
  routerHelper: RouterHelper;
}

/**
 * Aggregated portfolio for the reputation management dashboard.
 * Displays the average rating, reviews pending reply, some graphs
 * and a list of facilities that the user can drill down into to view
 * facility-specific metrics
 */
export function PortfolioView({ routerHelper }: PortfolioViewProps) {
  const { portfolio, statuses, pendingReviews, setReviewReplyDrawerOpen, metrics, isPremium } = useReputation();

  useEffect(() => {
    mixpanelEventHandler("Reputation - Page View - Portfolio");
  }, []);

  // If we haven't finished loading, don't attempt calculations
  if (statuses.portfolio === "loading" || statuses.metrics === "loading") return <CircularProgress size={50} />;
  if (statuses.portfolio === "error" || statuses.metrics === "error")
    return <Alert severity="error">Failed to load portfolio. Please refresh the page.</Alert>;

  const facilities = metrics.facilities ?? [];

  // ########################################################################################
  // Actionable reviews / feedback submissions that require a reply
  // ########################################################################################
  const numberOfReviewsPendingReply = facilities.reduce((acc, facility) => {
    return acc + facility.reviewsPendingReply;
  }, 0);

  // # feedback submissions without reply
  const numberOfFeedbackPendingReply = facilities.reduce((acc, facility) => {
    return acc + facility.feedbackSubmissionsPendingReply;
  }, 0);

  return (
    <Box>
      <TitleHeader title="Reputation Center" />
      <Grid container spacing={2} sx={{ maxWidth: (theme: Theme) => theme.breakpoints.values.xl }}>
        {!isPremium && (
          <Grid item xs={12}>
            <UpgradeCTABanner />
          </Grid>
        )}
        <Grid item xs={12}>
          <PortfolioViewHeader routerHelper={routerHelper} />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <RatingsDisplay
              averageRating={metrics.portfolio.averageRating}
              previousRating={metrics.portfolio.averageRating - metrics.portfolio.averageRatingWeeklyChange}
              ratings={metrics.portfolio.reviewCounts}
            />
          </Grid>
          <Grid item xs={6}>
            <SafeLeaseCard sx={{ p: 2 }}>
              <Stack spacing={2} direction="row" divider={<Divider flexItem orientation="vertical" />}>
                <Stack spacing={2} sx={{ flex: 1 }}>
                  <Stack direction="row" spacing={1} alignItems="flex-end">
                    <Typography variant="h3" fontWeight="bold">
                      {numberOfReviewsPendingReply}
                    </Typography>
                    <Typography variant="body1" color="grey.A100" sx={{ pb: 0.5 }}>
                      public reviews pending reply
                    </Typography>
                  </Stack>
                  <Typography color="grey.A100" variant="caption">
                    Public reviews are reviews left on Google. Responding to all reviews will help boost your reputation.
                  </Typography>
                </Stack>
                <Stack spacing={2} sx={{ flex: 1 }}>
                  <Stack direction="row" spacing={1} alignItems="flex-end">
                    <Typography variant="h3" fontWeight="bold">
                      {numberOfFeedbackPendingReply}
                    </Typography>
                    <Typography variant="body1" color="grey.A100" sx={{ pb: 0.5 }}>
                      private reviews pending reply
                    </Typography>
                  </Stack>
                  <Typography color="grey.A100" variant="caption">
                    Private reviews are captured in a private feedback form. Private reviews do not affect your Google rating.
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                <SafeLeaseButton
                  onClick={() => {
                    mixpanelEventHandler("Reputation - Drawer - Reply to Reviews");
                    setReviewReplyDrawerOpen(true);
                  }}
                  sx={{ flexGrow: 1 }}
                  variant="contained"
                  color="blue"
                  endIcon={<ReplyOutlined />}
                >
                  Respond
                </SafeLeaseButton>
              </Stack>
            </SafeLeaseCard>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <RatingBreakdownGraphs
            periods={metrics.portfolio.historicalReviewData}
            loading={statuses.portfolio === "loading"}
            liveDate={metrics.portfolio.goLiveDate}
          />
        </Grid>
        <Grid item xs={6}>
          <TeamPerformanceBoard />
        </Grid>
        <Grid item xs={12}>
          <FacilityList />
          <FacilityNotInRepList routerHelper={routerHelper} />
        </Grid>
        <AdminsOnly>
          <Grid item xs={12}>
            <PortfolioAdminTools />
          </Grid>
        </AdminsOnly>
      </Grid>
      <ReviewReplyDrawer
        publicReviews={statuses.pendingReviews === "success" ? pendingReviews : []}
        privateReviews={portfolio.facilities.flatMap((facility) =>
          facility.privateReviews.filter((sub: PrivateReview) => {
            // include the feedback submission if there's no response yet
            // or if the response is within the last 24 hours, so they can edit if they make a mistake when clicking 'responded'
            return (
              sub.responseSnippet === null ||
              sub.responseSnippet === "" ||
              (sub.responseSnippet && sub.responseDate && dayjs(sub.responseDate).isAfter(dayjs().subtract(1, "day")))
            );
          }),
        )}
      />
    </Box>
  );
}
